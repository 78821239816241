<template>
  <div class="wap-collect">
    <headerBar :title="$t('shang-pin-shou-cang-1')" @back="back"></headerBar>

    <div class="collect-list">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.noMore')"
        @load="onLoad"
      >
        <goodsList :list="dataList"></goodsList>
      </List>

      <div class="empty-box flex-column-center" v-if="dataList.length == 0">
        <img :src="emptyUrl" alt="" />
        <span>{{ $t('zan-wu-shu-ju') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Popup, List, Dialog } from 'vant'
import { getToken, getUserId } from '@/utils/auth'
import goodsList from '@/components/goodsList/wapList'
import headerBar from '@/components/header'
import { shopProductList, getShopList } from '@/api/shop'
export default {
  components: {
    Button,
    headerBar,
    Popup,
    goodsList,
    List
  },
  data() {
    return {
      form: {},
      showDialog: false,
      dataList: [],
      emptyUrl: require('@/assets/imgs/emtpy.svg'),
      page: {
        current: 1,
        size: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1,
      pageType: ''
    }
  },
  mounted() {
    this.pageType = this.$route.query.type
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onLoad() {
      this.page.current += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      // 店铺列表
      shopProductList({
        current: this.page.current,
        pageSize: this.page.size,
        UId: parseFloat(getUserId())
      })
        .then((res) => {
          if (isAdd) {
            this.dataList = this.dataList.concat(res.data.Items)
          } else {
            this.dataList = res.data.Items
          }
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.current) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    changeTab(i) {
      this.tabIndex = i
    },
    toAdd() {
      this.$router.push({
        name: 'wapAddAddress'
      })
    },
    chooseAddress(data) {
      if (this.pageType != 'choose') {
        return
      }
      sessionStorage.setItem('addressData', JSON.stringify(data))
      sessionStorage.setItem('addressFlag', 1)
      this.back()
    },
    addressAction(e, data) {
      if (e == 1) {
        //设为默认
        setDefaultAddress({
          ID: data.ID
        }).then((res) => {
          if (res.code == 0) {
            this.page.current = 1
            this.$toast(this.$t('she-zhi-cheng-gong'))

            this.init()
          } else {
            this.$toast(res.msg)
          }
        })
      } else {
        //删除
        Dialog.confirm({
          title: this.$t('que-ren'),
          message: this.$t('que-ren-shan-chu-0')
        }).then(() => {
          deleteAddress({
            ID: data.ID
          }).then((res) => {
            if (res.code == 0) {
              this.page.current = 1
              this.$toast(this.$t('shan-chu-cheng-gong'))
              this.init()
            } else {
              this.$toast(res.msg)
            }
          })
        })
      }
    }
  }
}
</script>